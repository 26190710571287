import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import classNames from 'classnames';

import { DangerouslySetInnerHtml } from 'layout';
import Image from 'common/Image';
import Cards from 'components/Cards';
import OrderedList from 'components/OrderedList';
import Video from 'components/Video';

import ArticleRecommendedProducts from './ArticleRecommendedProducts/ArticleRecommendedProducts';
import { ArticleSectionProps } from './models';

import './ArticleSection.scss';
import './ShadowArticleSection.scss';

export const ArticleSection = ({
  title,
  description,
  additionalDescription,
  media: [media],
  weRecommended: [weRecommended],
  instruction: [instruction],
  logo,
  logoAlt,
  cards,
}: ArticleSectionProps) => {
  const isRecommendedExists = !!weRecommended;
  const isInstructionsExists = !!instruction;
  const isVideoExists = !!(media && media.videoId);
  const isImageExists = !!(media && media.image);
  const isTitleExists = !!title;

  const isStandaloneParagraph = [
    isRecommendedExists,
    isInstructionsExists,
    isVideoExists,
    isImageExists,
    isTitleExists,
  ].every((item) => !item);

  const CSSclassNames = classNames(`article-section`, {
    'article-section__standalone-paragraph row': isStandaloneParagraph,
  });

  return (
    <Row>
      <Col>
        <article data-testid="article-section" className={CSSclassNames}>
          {isStandaloneParagraph ? (
            <Col>
              {cards?.length ? <Cards cards={cards} /> : null}
              <DangerouslySetInnerHtml html={description} />
            </Col>
          ) : (
            <>
              {isTitleExists || description ? (
                <Row>
                  <Col>
                    {isTitleExists ? <h2 className="article-section__title">{title}</h2> : null}
                    {description ? (
                      <DangerouslySetInnerHtml
                        html={description}
                        className="list__marker-in-circle list__bullet-marker"
                      />
                    ) : null}
                  </Col>
                </Row>
              ) : null}

              {isImageExists || isVideoExists ? (
                <Row>
                  <Col>
                    {isImageExists && media.image !== null ? (
                      <Image imageData={media.image} alt={media.alt || ''} />
                    ) : null}
                    {isVideoExists ? <Video videoId={media.videoId} /> : null}
                  </Col>
                </Row>
              ) : null}

              {additionalDescription ? (
                <Row>
                  <Col>
                    <DangerouslySetInnerHtml
                      html={additionalDescription}
                      className="list__marker-in-circle list__bullet-marker"
                    />
                  </Col>
                </Row>
              ) : null}

              {isRecommendedExists || isInstructionsExists ? (
                <Row>
                  {isRecommendedExists ? (
                    <Col lg={isInstructionsExists ? 6 : 12}>
                      <h3 className="article-section__subtitle">
                        {weRecommended.recommendedLabel}
                      </h3>
                      <ArticleRecommendedProducts recommended={weRecommended.recommended} />
                    </Col>
                  ) : null}

                  {isInstructionsExists ? (
                    <Col lg={isRecommendedExists ? 6 : 12}>
                      <h3 className="article-section__subtitle article-section__subtitle--instructions">
                        {instruction.instructionLabel}
                      </h3>
                      <OrderedList
                        orderedList={instruction.instructions}
                        moreButtonText={instruction.instructionMoreButtonLabel}
                        numberOfDisplayedItems={4}
                      />
                    </Col>
                  ) : null}
                </Row>
              ) : null}
              {logo ? (
                <div className="article-section__logo">
                  <Image imageData={logo} alt={logoAlt || ''} />
                </div>
              ) : null}
            </>
          )}
        </article>
      </Col>
    </Row>
  );
};

export default ArticleSection;
