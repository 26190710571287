import React, { FC } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { DangerouslySetInnerHtml } from 'layout';
import Image from 'common/Image';

import { HowToUseProps } from './models';

import './HowToUse.scss';

const HowToUse: FC<HowToUseProps> = ({ sectionTitle, howToUseItem }) => (
  <Row data-testid="how-to-use" className="how-to-use">
    <Col>
      <h2 className="how-to-use__title">{sectionTitle}</h2>

      <Row className="how-to-use__list">
        {howToUseItem.map(({ title, disclaimer, textList }) => (
          <Col className="how-to-use__column" key={title} lg={4} md={6}>
            <DangerouslySetInnerHtml html={title} className="how-to-use__sub-title" element="h3" />

            {textList.map(({ text, icon, iconAlt }) => (
              <div className="how-to-use__row" key={iconAlt}>
                <div className="how-to-use__row-wrapper">
                  {icon?.imagePicker ? (
                    <Image
                      className="how-to-use__row-icon"
                      imageData={icon.imagePicker}
                      objectFit="contain"
                      alt={iconAlt}
                      objectPosition="0 0"
                    />
                  ) : null}
                  <DangerouslySetInnerHtml html={text} className="how-to-use__row-text" />
                </div>
              </div>
            ))}

            <DangerouslySetInnerHtml html={disclaimer} className="how-to-use__disclaimer" />
          </Col>
        ))}
      </Row>
    </Col>
  </Row>
);

export default HowToUse;
