import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { graphql } from 'gatsby';

import { DangerouslySetInnerHtml } from 'layout';

import { TextBoxProps } from './models';

import './TextBox.scss';

export const TextBox = ({ text, title }: TextBoxProps) => (
  <Row data-testid="textbox" className="textbox">
    <Col>
      <h2 className="textbox__title">{title}</h2>
      <div className="textbox__text">
        <DangerouslySetInnerHtml html={text} />
      </div>
    </Col>
  </Row>
);

export const query = graphql`
  fragment FragmentTextBox on Textbox {
    title
    text
  }
`;
export default TextBox;
