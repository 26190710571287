import React, { FC } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import classNames from 'classnames';
import useScreenRecognition from 'hooks/useScreenRecognition';

import DangerouslySetInnerHtml from 'layout/DangerouslySetInnerHtml';
import { VisualType, VisualVariant } from 'common/Button';
import Image from 'common/Image';
import BannerImage from 'components/Banner/BannerImage';
import LinkButton from 'components/LinkButton';
import { BANNER_BACKGROUND_COLOR } from 'utils/constants';

import { BannerProps } from './models';

import './HomeBanner.scss';

const HomeBanner: FC<BannerProps> = ({
  description,
  disclaimer,
  mainButton: [mainButton],
  mainButtonAriaLabel,
  secondaryButton: [secondaryButton],
  secondaryButtonAriaLabel,
  title,
  alt,
  background: bg,
  bannerImage,
  imageProduct,
  imageProductAlt,
  bannerTheme,
}) => {
  const { isMobile } = useScreenRecognition();
  const background = bg || BANNER_BACKGROUND_COLOR.pink;
  const bannerThemeVariant = bannerTheme?.[0];

  return (
    <Row
      data-testid="home-banner"
      className={classNames('home-banner', {
        [`home-banner--${background}`]: background,
        [`home-banner--${bannerThemeVariant}`]: bannerThemeVariant,
      })}
    >
      <div className="home-banner__information-section">
        <Col className="home-banner__information">
          <DangerouslySetInnerHtml element="h2" html={title} className="home-banner__title" />
          <DangerouslySetInnerHtml
            html={description}
            className="home-banner__description"
            data-testid="home-banner-description"
          />
          {disclaimer ? (
            <Col className="home-banner__disclaimer" data-testid="disclaimer-section">
              <DangerouslySetInnerHtml html={disclaimer} />
            </Col>
          ) : null}
          <div className="home-banner__links">
            {mainButton ? (
              <LinkButton
                text={mainButton.name}
                type={VisualType.Discover}
                url={mainButton.url}
                ariaLabel={mainButtonAriaLabel}
                variant={
                  background === BANNER_BACKGROUND_COLOR.pink
                    ? VisualVariant.Light
                    : VisualVariant.Dark
                }
              />
            ) : null}
            {secondaryButton ? (
              <LinkButton
                text={secondaryButton.name}
                type={VisualType.Secondary}
                url={secondaryButton.url}
                ariaLabel={secondaryButtonAriaLabel}
                variant={
                  background === BANNER_BACKGROUND_COLOR.pink
                    ? VisualVariant.Light
                    : VisualVariant.Dark
                }
              />
            ) : null}
          </div>
        </Col>
      </div>
      <div className="home-banner__image-section">
        <div className="home-banner__image">
          <BannerImage image={bannerImage} alt={alt} />
        </div>
      </div>

      {imageProduct && !isMobile ? (
        <div className="home-banner__image-product" data-testid="image-product-section">
          <Image imageData={imageProduct} alt={imageProductAlt} />
        </div>
      ) : null}
    </Row>
  );
};

export default HomeBanner;
