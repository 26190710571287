import React, { FC, useState } from 'react';

import { VisualType, VisualVariant } from 'common/Button';
import LinkButton from 'components/LinkButton';

import { OptionsProps } from './models';

import './Options.scss';

const Options: FC<OptionsProps> = ({ options, ctaTitle, optionLabel }: OptionsProps) => {
  const [selected, setSelected] = useState('');
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelected(e.target.value);
  };

  return (
    <div className="options">
      <select value={selected} onChange={handleChange}>
        <option hidden value={optionLabel}>
          {optionLabel}
        </option>
        {options?.map((el) => (
          <option key={el.url} value={el.url}>
            {el.title}
          </option>
        ))}
      </select>
      {selected ? (
        <LinkButton
          text={ctaTitle}
          type={VisualType.Primary}
          url={selected}
          ariaLabel={ctaTitle}
          variant={VisualVariant.Light}
        />
      ) : (
        <button type="button" disabled>
          {ctaTitle}
        </button>
      )}
    </div>
  );
};

export default Options;
