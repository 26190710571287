import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import debounce from 'lodash/debounce';

import Image from 'common/Image';

import { VideoProps } from './models';

import './Video.scss';

const Video: FC<VideoProps> = ({
  videoId,
  autoplay = false,
  controls = true,
  loop = false,
  muted = false,
}) => {
  const [playerHeight, setPlayerHeight] = useState('100px');
  const [videoPlayed, setVideoPlayed] = useState(autoplay);

  const {
    sharedComponentsSettings: { playerIcon, playerWhiteIcon, playerText, playVideoAriaLabel },
  } = useStaticQuery(graphql`
    {
      sharedComponentsSettings {
        playerIcon {
          ...ImageFragment
        }
        playerWhiteIcon {
          ...ImageFragment
        }
        playerText
        playVideoAriaLabel
      }
    }
  `);

  const overlayElement: React.MutableRefObject<HTMLDivElement | null> = useRef(null);
  // Aspect ratio 16:9
  const aspectRatio = 0.5625;

  const handleResize = useCallback(
    debounce(() => {
      const playerWidth = overlayElement.current?.offsetWidth;

      if (playerWidth) {
        setPlayerHeight(`${playerWidth * aspectRatio}px`);
      }
    }, 100),
    []
  );

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  const backgroundImageStyle = `url(https://img.youtube.com/vi/${videoId}/0.jpg)`;

  return (
    <div className="video" data-testid="video-container">
      <div ref={overlayElement} className="video__container" style={{ height: playerHeight }}>
        {videoPlayed ? (
          <iframe
            src={`https://www.youtube.com/embed/${videoId}?autoplay=1&loop=${loop}&controls=${Number(
              controls
            )}&mute=${muted}`}
            title={`https://www.youtube.com/embed/${videoId}/video`}
            frameBorder="0"
            allow="autoplay; encrypted-media"
            allowFullScreen
            className="video__player"
            width="100%"
            height="100%"
          />
        ) : (
          <div
            className="video__overlay"
            style={{
              backgroundImage: backgroundImageStyle,
            }}
            role="button"
            tabIndex={0}
            aria-label={playVideoAriaLabel}
            onClick={() => setVideoPlayed(true)}
          >
            <div className="video__playerIcon">
              {playerIcon ? <Image imageData={playerIcon} alt="" /> : null}
            </div>
            <div className="video__playerWhiteIcon">
              <span className="video__icon-text">{playerText}</span>
              {playerWhiteIcon ? <Image imageData={playerWhiteIcon} alt="" /> : null}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Video;
