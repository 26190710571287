import React, { FC } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { DangerouslySetInnerHtml } from 'layout';
import BannerImage from 'components/Banner/BannerImage';
import Options from 'components/Options';
import parseAnchor from 'utils/parseAnchor';

import { SelectorBannerProps } from './models';

import './SelectorBanner.scss';

const SelectorBanner: FC<SelectorBannerProps> = ({
  secondaryNavigationAnchor,
  image,
  imageIsIcon,
  alt,
  title,
  description,
  options,
  ctaTitle,
  optionLabel,
}) => {
  const anchorParsed = parseAnchor(secondaryNavigationAnchor);

  return (
    <Row
      data-testid="selector-banner"
      id={anchorParsed}
      className={`selector-banner selector-banner__${imageIsIcon ? 'full' : 'image'}-text`}
    >
      {!imageIsIcon ? (
        <div className="selector-banner__image">
          <BannerImage image={image} alt={alt} />
        </div>
      ) : null}
      <div className="selector-banner__information-section">
        <Col className="selector-banner__information">
          <h2 className="selector-banner__title ">{title}</h2>
          <DangerouslySetInnerHtml className="selector-banner__description" html={description} />
        </Col>
        <Col className="selector-banner__options">
          {imageIsIcon ? (
            <div className="selector-banner__subtitle">
              <p className="selector-banner__subtitle-text">{alt}</p>
              <div className="selector-banner__subtitle-icon">
                <BannerImage image={image} alt={alt} />
              </div>
            </div>
          ) : null}
          <Options options={options} ctaTitle={ctaTitle} optionLabel={optionLabel} />
        </Col>
      </div>
    </Row>
  );
};

export default SelectorBanner;
