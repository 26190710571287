import React, { FC } from 'react';

import ArticleSection from 'components/ArticleSection';
import CampaignBanner from 'components/CampaignBanner';
import ContactUsText from 'components/ContactUsText';
import CouponSection from 'components/CouponSection';
import DonateBanner from 'components/DonateBanner';
import Faq from 'components/Faq';
import FullWidthTeaser from 'components/FullWidthTeaser';
import GlobalContactFormHolder from 'components/GlobalContactFormHolder';
import HowToUse from 'components/HowToUse';
import IconsList from 'components/IconsList';
import IframeLoader from 'components/IframeLoader';
import InfoBlock from 'components/InfoBlock';
import Ingredients from 'components/Ingredients';
import {
  ACLListing,
  ArticleCampaignListing,
  ArticlePromoListing,
  ExpertiseListing,
  InfoListing,
  LinkListing,
  ProductsListing,
  StainsListing,
} from 'components/Listing';
import ContentListing from 'components/Listing/Articles/ContentListing';
import NewsletterBanner from 'components/NewsletterBanner';
import SelectorBanner from 'components/SelectorBanner';
import ShoppingButtons from 'components/ShoppingButtons';
import ShoppingOptions from 'components/ShoppingOptions';
import SocialSection from 'components/SocialSection';
import StoreFinder from 'components/StoreFinder';
import StoresList from 'components/StoresList';
import Teaser from 'components/Teaser';
import TextBox from 'components/TextBox';
import TextWithImage from 'components/TextWithImage';
import TextWithMedia from 'components/TextWithMedia';
import TextWithPopUp from 'components/TextWithPopUp/TextWithPopUp';
import TextWithVideo from 'components/TextWithVideo';
import UpparelBanner from 'components/UpparelBanner';
import WhereToBuyListing from 'components/WhereToBuyListing';
import WithBackgroundColor from 'components/WithBackgroundColor';

import HomeBanner from '../components/HomeBanner';

export const COMPONENTS = {
  teaser: 'teaser',
  articleCategoryListingListing: 'articleCategoryListingListing',
  articleCategoryListing: 'articleCategoryListing',
  stainsListing: 'stainsListing',
  expertiseListing: 'expertiseListing',
  articlePromoListing: 'articlePromoListing',
  articleCampaignListing: 'articleCampaignListing',
  fullWidthTeaser: 'fullWidthTeaser',
  newsletterBanner: 'newsletterNodePicker',
  productsCategoryListingListing: 'productsCategoryListingListing',
  productsCategoryListing: 'productsCategoryListing',
  productsListing: 'productsListing',
  textWithImage: 'textWithImage',
  shoppingOptions: 'shoppingOptions',
  faq: 'faq',
  textBox: 'textbox',
  ingredients: 'ingredients',
  howToUse: 'howToUse',
  textWithVideo: 'textWithVideo',
  articleSection: 'articleSection',
  iconsList: 'iconsList',
  iframe: 'iframe',
  contactUsText: 'contactUsText',
  globalContactForm: 'globalContactForm',
  storeFinder: 'storeFinder',
  stores: 'stores',
  whereToBuyListing: 'whereToBuyListing',
  shoppingButtons: 'shoppingButtons',
  infoCardsListing: 'infoCardsListing',
  linkListing: 'linkListing',
  donateBanner: 'donateBanner',
  selectorBanner: 'selectorBanner',
  couponSection: 'couponSection',
  infoBlock: 'infoBlock',
  contentListing: 'contentListing',
  textWithPopUp: 'textWithPopUp',
  textWithMedia: 'textWithMedia',
  campaignBanner: 'campaignBanner',
  upparelBanner: 'upparelBanner',
  socialSection: 'socialSection',
  homeBanner: 'homeBanner',
};

const BodyRenderer: FC<PageContent.BodyType> = ({ component, value }) => {
  value.backgroundColor === null && delete value.backgroundColor;

  switch (component) {
    case COMPONENTS.homeBanner: {
      return <HomeBanner {...value} />;
    }
    case COMPONENTS.articleSection: {
      return (
        <WithBackgroundColor {...value}>
          <ArticleSection {...value} />
        </WithBackgroundColor>
      );
    }
    case COMPONENTS.iconsList: {
      return (
        <WithBackgroundColor {...value}>
          <IconsList {...value} />
        </WithBackgroundColor>
      );
    }
    case COMPONENTS.teaser: {
      return <Teaser {...value} />;
    }
    case COMPONENTS.articleCategoryListingListing: {
      return (
        <WithBackgroundColor {...value}>
          <ACLListing {...value} />
        </WithBackgroundColor>
      );
    }
    case COMPONENTS.articleCategoryListing:
    case COMPONENTS.stainsListing: {
      return (
        <WithBackgroundColor {...value}>
          <StainsListing {...value} />
        </WithBackgroundColor>
      );
    }
    case COMPONENTS.expertiseListing: {
      return (
        <WithBackgroundColor {...value}>
          <ExpertiseListing {...value} />
        </WithBackgroundColor>
      );
    }
    case COMPONENTS.articlePromoListing: {
      return (
        <WithBackgroundColor {...value}>
          <ArticlePromoListing {...value} />
        </WithBackgroundColor>
      );
    }
    case COMPONENTS.articleCampaignListing: {
      return (
        <WithBackgroundColor {...value}>
          <ArticleCampaignListing {...value} />
        </WithBackgroundColor>
      );
    }
    case COMPONENTS.fullWidthTeaser: {
      return <FullWidthTeaser {...value} />;
    }
    case COMPONENTS.newsletterBanner: {
      return <NewsletterBanner {...value} />;
    }

    case COMPONENTS.productsCategoryListingListing:
    case COMPONENTS.productsCategoryListing:
    case COMPONENTS.productsListing: {
      return (
        <WithBackgroundColor {...value}>
          <ProductsListing {...value} />
        </WithBackgroundColor>
      );
    }
    case COMPONENTS.textWithImage: {
      return <TextWithImage {...value} />;
    }
    case COMPONENTS.faq: {
      return (
        <WithBackgroundColor {...value}>
          <Faq {...value} />
        </WithBackgroundColor>
      );
    }
    case COMPONENTS.textBox: {
      return (
        <WithBackgroundColor {...value}>
          <TextBox {...value} />
        </WithBackgroundColor>
      );
    }
    case COMPONENTS.ingredients: {
      return (
        <WithBackgroundColor {...value}>
          <Ingredients {...value} />
        </WithBackgroundColor>
      );
    }
    case COMPONENTS.howToUse: {
      return (
        <WithBackgroundColor {...value}>
          <HowToUse {...value} />
        </WithBackgroundColor>
      );
    }
    case COMPONENTS.textWithVideo: {
      return (
        <WithBackgroundColor {...value}>
          <TextWithVideo {...value} />
        </WithBackgroundColor>
      );
    }
    case COMPONENTS.shoppingOptions: {
      return <ShoppingOptions {...value} />;
    }

    case COMPONENTS.iframe: {
      return (
        <WithBackgroundColor {...value}>
          <IframeLoader {...value} />
        </WithBackgroundColor>
      );
    }

    case COMPONENTS.contactUsText: {
      return (
        <WithBackgroundColor {...value}>
          <ContactUsText {...value} />
        </WithBackgroundColor>
      );
    }

    case COMPONENTS.globalContactForm: {
      return (
        <WithBackgroundColor {...value}>
          <GlobalContactFormHolder {...value} />
        </WithBackgroundColor>
      );
    }

    case COMPONENTS.storeFinder: {
      return (
        <WithBackgroundColor {...value}>
          <StoreFinder {...value} />
        </WithBackgroundColor>
      );
    }

    case COMPONENTS.stores: {
      return <StoresList {...value} />;
    }

    case COMPONENTS.whereToBuyListing: {
      return <WhereToBuyListing {...value} />;
    }

    case COMPONENTS.shoppingButtons: {
      return <ShoppingButtons {...value} />;
    }

    case COMPONENTS.infoCardsListing: {
      return (
        <WithBackgroundColor {...value}>
          <InfoListing {...value} />
        </WithBackgroundColor>
      );
    }

    case COMPONENTS.linkListing: {
      return (
        <WithBackgroundColor {...value}>
          <LinkListing {...value} />
        </WithBackgroundColor>
      );
    }

    case COMPONENTS.donateBanner: {
      return <DonateBanner {...value} />;
    }

    case COMPONENTS.selectorBanner: {
      return <SelectorBanner {...value} />;
    }

    case COMPONENTS.couponSection: {
      return <CouponSection {...value} />;
    }

    case COMPONENTS.infoBlock: {
      return (
        <WithBackgroundColor {...value}>
          <InfoBlock {...value} />
        </WithBackgroundColor>
      );
    }

    case COMPONENTS.contentListing: {
      return <ContentListing {...value} />;
    }

    case COMPONENTS.textWithPopUp: {
      return (
        <WithBackgroundColor {...value}>
          <TextWithPopUp {...value} />
        </WithBackgroundColor>
      );
    }

    case COMPONENTS.textWithMedia: {
      return (
        <WithBackgroundColor {...value}>
          <TextWithMedia {...value} />
        </WithBackgroundColor>
      );
    }

    case COMPONENTS.campaignBanner: {
      return <CampaignBanner {...value} />;
    }

    case COMPONENTS.upparelBanner: {
      return <UpparelBanner {...value} />;
    }

    case COMPONENTS.socialSection: {
      return (
        <WithBackgroundColor {...value}>
          <SocialSection {...value} />
        </WithBackgroundColor>
      );
    }

    default:
      return null;
  }
};

export default BodyRenderer;
